<template>
  <div class='border-t bg-white flex flex-col py-12'>
    <div class='layout-container w-full bg-white flex flex-col-reverse lg:flex-row-reverse lg:gap-x-8 gap-y-8 justify-between px-4 lg:px-0 text-xs lg:text-sm text-gray-700'>
      <div class='flex flex-col justify-between items-start lg:items-end'>
        <div>
          <img :src='eventConfigLogoImageUrl'
             class='h-12 cursor-pointer mb-6 w-auto'
             @click='goToHome'/>
        </div>
        <div
          v-if='hasValidToken' 
          class='text-left uppercase border px-4 py-2 text-center rounded-md hover:shadow-md cursor-pointer'
          @click='logoutApp'>
          logout
        </div>
      </div>
      <div class='text-sm'>
        <div class='mt-2 flex flex-col lg:flex-row justify-start items-center lg:items-start'>
          <span class='uppercase inline-block opacity-70 w-full lg:w-48'>Tel</span>
          <span class='block text-left w-full lg:w-auto'>02-549-7451~65</span>
        </div>
        <div class='mt-2 flex flex-col lg:flex-row justify-start items-center lg:items-start'>
          <span class='uppercase inline-block opacity-70 w-full lg:w-48'>주소</span> 
          <span class='block text-left w-full lg:w-auto'>서울특별시 서초구 사평대로 343 (반포동, 제일약품)</span>
        </div>
        <div class='mt-2 flex flex-col lg:flex-row justify-start items-center lg:items-start'>
          <span class='uppercase inline-block opacity-70'>© 2022 JEIL PHARMACEUTICAL CO.,LTD. All rights reserved</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  name: 'JeilPharmLayoutFooter',
  computed: {
    ...mapState('users', [
      'hasValidToken',
    ]),
    ...mapGetters('events', [
      'eventConfigLogoImageUrl',
    ]),
  },
  methods: {
    ...mapActions('users', [
      'logout',
      'checkTokenStatus',
    ]),
    logoutApp () {
      this.logout().then(() => {
        this.$router.push({name: 'Login'})
      })
    },
    goToHome () {
      this.$router.push({name: 'Home'}).catch(() => {})
    },
  },
  mounted () {
    this.checkTokenStatus().then(() => {
      // if (!this.hasValidToken) {
      //   this.$router.replace({name: 'Login'}).catch(() => {})
      // }
    })
  }
}
</script>
